var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[(_vm.message.createdByType)?_c('v-row',{key:_vm.keyMessageText},[_c('v-col',{attrs:{"cols":_vm.cols}},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.message.nextMessageHourApart},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({class:[
              'message',
              _vm.message.createdByType === 'PATIENT'
                ? 'message--sent'
                : 'message--received'
            ],attrs:{"rounded":"xl","flat":""}},'v-card',attrs,false),on),[_c('v-card-subtitle',{staticClass:"row",staticStyle:{"margin-top":"0px","margin-left":"0px"}},[_c('div',{staticClass:"circle type-send"},[_c('div',[_c('strong',{staticClass:"initial-name"},[_vm._v(" "+_vm._s(_vm.userInitialName)+" ")])])]),_c('b',{staticStyle:{"margin-left":"15px","margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")])]),_c('v-card-text',[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[_c('b',[_vm._v(_vm._s(_vm.message.conversationSubject))])]),_c('v-list-item-action',[_vm._v(" "+_vm._s(_vm.messageSentDate)+" ")])],1)],1),_c('v-card-text',{ref:`message-text-${_vm.message.id}`,class:{ collapsed: _vm.collapsed, 'message-text': true }},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.expandMessage)}})]),(_vm.message.attachmentPath)?_c('v-card-actions',{staticClass:"message-attachment"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({on:{"click":function($event){return _vm.downloadAttachment(_vm.message.attachmentPath)}}},on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,true)},[[_vm._v(_vm._s(_vm.message.attachmentName))]],2)],1):_vm._e()],1)]}}],null,false,2902525192)},[_c('span',[_vm._v(_vm._s(_vm.sentDate))])])],1)],1):_vm._e(),(_vm.message.createdByType)?_c('div',[_c('div',{class:[
        'picker',
        _vm.message.createdByType === 'PATIENT'
          ? 'picker--sent'
          : 'picker--received'
      ],staticStyle:{"width":"8px","height":"8px","margin-top":"10px"}}),_c('div',{class:[
        'picker',
        _vm.message.createdByType === 'PATIENT'
          ? 'picker--sent'
          : 'picker--received'
      ],staticStyle:{"width":"12px","height":"12px"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }