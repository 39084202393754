<template>
  <v-col>
    <v-row v-if="message.createdByType" :key="keyMessageText">
      <v-col :cols="cols">
        <v-tooltip top :disabled="message.nextMessageHourApart">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              rounded="xl"
              flat
              v-bind="attrs"
              v-on="on"
              :class="[
                'message',
                message.createdByType === 'PATIENT'
                  ? 'message--sent'
                  : 'message--received'
              ]"
            >
              <v-card-subtitle
                class="row"
                style="margin-top: 0px; margin-left: 0px"
              >
                <div class="circle type-send">
                  <div>
                    <strong class="initial-name">
                      {{ userInitialName }}
                    </strong>
                  </div>
                </div>
                <b style="margin-left: 15px; margin-top: 10px">
                  {{ getTitle }}
                </b>
              </v-card-subtitle>
              <v-card-text>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <b>{{ message.conversationSubject }}</b>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ messageSentDate }}
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
              <v-card-text
                :ref="`message-text-${message.id}`"
                :class="{ collapsed: collapsed, 'message-text': true }"
              >
                <div v-html="expandMessage"></div>
              </v-card-text>
              <v-card-actions
                class="message-attachment"
                v-if="message.attachmentPath"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span
                      v-on="on"
                      @click="downloadAttachment(message.attachmentPath)"
                    >
                      <v-icon>mdi-paperclip</v-icon>
                    </span>
                  </template>
                  <template>{{ message.attachmentName }}</template>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </template>
          <span>{{ sentDate }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div v-if="message.createdByType">
      <div
        :class="[
          'picker',
          message.createdByType === 'PATIENT'
            ? 'picker--sent'
            : 'picker--received'
        ]"
        style="width: 8px; height: 8px; margin-top: 10px"
      ></div>
      <div
        :class="[
          'picker',
          message.createdByType === 'PATIENT'
            ? 'picker--sent'
            : 'picker--received'
        ]"
        style="width: 12px; height: 12px"
      ></div>
    </div>
  </v-col>
</template>

<script>
import moment from 'moment';
require('moment-timezone');
import { auth } from '../../sharedPlugin';
import { mapState } from 'vuex';

export default {
  props: ['message', 'cols'],
  data: () => ({
    moment,
    collapsed: false,
    keyMessageText: Math.random()
  }),
  computed: {
    ...auth.mapComputed(['user']),
    ...mapState('supportChat/conversations', {
      conversation: 'selected'
    }),
    getTitle() {
      if (this.message && this.message.createdByType === 'PATIENT') {
        return 'You';
      }
      return this.conversation?.groupName;
    },
    userInitialName() {
      if (this.message && this.message.createdByType === 'PATIENT') {
        if (this.message.createdByName) {
          return this.initials(this.message.createdByName);
        }
      }
      return this.initials(this.conversation?.groupName);
    },
    sentDate() {
      return moment(this.message.createdAt).calendar() ?? '';
    },
    messageSentDate() {
      if (this.message && this.message.createdAt) {
        var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
        var abbr = '(' + timeZoneAbbr + ')';

        let diff = moment().diff(this.message.createdAt, 'days');

        if (diff < 1) {
          let date = moment(this.message.createdAt).format('h:mma');
          return `${date} ${abbr}`;
        } else if (diff < 2) return 'Yesterday';
        else return moment(this.message.createdAt).format('MM/DD/YYYY');
      } else {
        return moment().format('MM/DD/YYYY');
      }
    },
    text() {
      return this.message.message;
    },
    createdByName() {
      return this.message.createdByType === 'USER'
        ? this.message.createdByName
        : 'You';
    },
    truncatedMessage() {
      if (
        this.message &&
        this.message.message &&
        this.message.message.length <= 100
      ) {
        return this.message.message;
      }
      return this.message && this.message.message
        ? this.message.message.substring(0, 100) + '...'
        : 'NDA';
    },
    expandMessage() {
      if (
        this.message &&
        this.message.message &&
        this.message.message.length <= 100
      ) {
        return this.message.message;
      }
      return this.message && this.message.message ? this.message.message : '';
    }
  },
  methods: {
    downloadAttachment(attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    },
    toggle() {
      this.collapsed = !this.collapsed;
    },
    initials(name) {
      return name
        ?.split(' ')
        .filter((item, index) => index < 2)
        .map((n) => n[0])
        .join('');
    }
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-card {
  background-color: #d3d3d3;
}
:root .theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: black;
  --v-anchor-base: #024bb9;
}
:root .theme--dark.v-card > .v-card__text,
.theme--dark.v-card .v-card__subtitle {
  color: white;
  --v-anchor-base: #75caf6;
}
.download-button {
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
}
.message {
  width: 65%;
  margin: 0px 15px;
  padding: 10px 20px;
  position: relative;

  &:last-child {
    margin-bottom: 10px !important;
  }

  & .v-card__subtitle {
    padding: 5px 0px;
  }
  & .v-card__text {
    padding: 5px 0px;
    line-height: 1rem;
  }

  &--received {
    float: left;
    background: #e7f4f5 !important;
    // border: 1px solid rgb(0, 219, 58) !important;
    border-radius: 16px !important;
  }
  &--sent {
    float: right;
    background: #feefe2 !important;
    //border: 1px solid rgb(90, 58, 167) !important;
    border-radius: 16px !important;
  }

  &-attachment {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 0 0 0 10px;
    border-top: none;
    cursor: pointer;
  }
}
.tiptap-vuetify-editor {
  cursor: text !important;
}

.collapsed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-text {
  & .small-text {
    font-size: 0.8rem;
    position: absolute;
    color: #024bb9;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.circle-m-send {
  height: 20px;
  width: 20px;
  border-radius: 50%;

  background: #feefe2 !important;
}

.circle-p-send {
  height: 10px;
  width: 10px;
  border-radius: 50%;

  background: #feefe2 !important;
}

.initial-name {
  font-size: 15 !important;
  font-weight: 600 !important;
  font-family: 'SF Pro Display';
  text-align: center !important;
}
.type-send {
  background: #a0d5d7 !important;
}

.picker {
  border-radius: 50%;
  &--sent {
    background: #feefe2;
    float: right;
  }
  &--received {
    background: #e7f4f5;
    float: left;
  }
}
</style>
<style>
.collapsed br {
  display: block !important;
}
</style>
