<template>
  <div id="support-chat">
    <div ref="messageContainer" class="messages-card" style="height: 80vh">
      <InfiniteLoading
        :identifier="infiniteLoadingKey"
        class="mt-4 mb-4"
        direction="top"
        @infinite="fetchMessages"
      >
        <div slot="spinner">
          <v-progress-circular size="18" color="primary" indeterminate />
          <p>Loading...</p>
        </div>
        <div slot="no-more">No more messages</div>
        <div slot="no-results">No results in messages</div>
      </InfiniteLoading>
      <v-row
        v-for="(message, index) in filteredMessages"
        :key="index"
        ref="messageComponents"
      >
        <Message v-bind:message="message" :cols="cols" />
      </v-row>
    </div>

    <v-footer
      height="60px"
      style="
        background-color: transparent;
        margin-bottom: 5px;
        width: 100%;
        justify-content: center;
        position: absolute;
        bottom: 0px;
      "
    >
      <Btn theme="primary" width="90%" @click="goToReply">REPLY</Btn>
    </v-footer>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Message from '../../components/supportChat/Message.vue';
import { auth, supportChat, partner } from '../../sharedPlugin';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SupportChatMessagesList',
  components: {
    InfiniteLoading,
    Message
    // TextEditor
  },
  props: ['hideToolbar'],
  data() {
    return {
      infiniteLoadingKey: Math.random(),
      newMessageLoading: false,
      newMessage: '',
      newAttachment: null
    };
  },
  computed: {
    // Global
    ...auth.mapComputed(['user']),
    ...mapState('supportChat/messages', {
      itemsMessages: 'items',
      paginationMessages: 'pagination'
    }),
    ...mapState('supportChat/conversations', {
      selectedConversation: 'selected'
    }),
    ...partner.mapComputed(['getPartner']),
    // Local
    filteredMessages() {
      let remapMessages = [...this.itemsMessages].sort(
        (a, b) => a.createdAt - b.createdAt
      );
      let previousMsg = (remapMessages[0] = {
        ...remapMessages[0],
        nextMessageHourApart: true
      });

      for (let i = 1; i < remapMessages.length; i++) {
        const oneHour = 1000 * 60 * 60;
        const itemCreatedAt = new Date(remapMessages[i].createdAt);
        const prevCreatedAt = new Date(previousMsg.createdAt);
        let timeDifference = itemCreatedAt.getTime() - prevCreatedAt.getTime();
        let currentMsg = {
          ...remapMessages[i],
          nextMessageHourApart: timeDifference > oneHour
        };

        remapMessages[i] = { ...currentMsg };
        previousMsg = currentMsg;
      }
      return remapMessages;
    }
  },
  watch: {
    selectedConversation: {
      handler: function (val) {
        if (val) {
          this.resetStateMessages();
          this.infiniteLoadingKey = Math.random();
        }
      },
      immediate: true
    }
  },
  methods: {
    // Global
    ...supportChat.conversations.mapMethods(['setSelectedConversation']),
    ...supportChat.messages.mapMethods([
      'unshiftItemsMessages',
      'setPaginationMessages',
      'resetStateMessages'
    ]),
    ...mapActions('supportChat/messages', {
      getMessages: 'fetchData',
      addMessage: 'add'
    }),
    ...mapActions('supportChat/conversations', {
      readConversation: 'read'
    }),
    // Local
    async fetchMessages($state) {
      await this.getMessages({
        conversationId: this.selectedConversation?.id
      }).then((data) => {
        if (data && data.items && data.items.length) {
          this.setPaginationMessages({
            itemsLength: data.itemsLength,
            page: this.paginationMessages.page + 1,
            itemsPerPage: 10
          });
          this.unshiftItemsMessages(data.items?.reverse());
          if ($state) {
            $state.loaded();
          }
          if (data.page === 1) {
            this.readConversation(this.selectedConversation?.id);

            setTimeout(() => {
              this.$store.dispatch(
                'messages/pullNotificationUnreadCount',
                true
              );
            }, 200);
          }
        } else {
          if ($state) {
            $state.complete();
          }
        }
      });
    },
    handleBack() {
      this.setSelectedConversation(null);
      this.$emit('back');
    },
    goToReply() {
      this.$router.push({ path: '/messages/reply' });
    },
    async handleAddMessage() {
      this.newMessageLoading = true;
      let createdByName = this.user?.fullName || null;

      if (
        this.getPartner &&
        this.getPartner.ownerName &&
        this.getPartner.name
      ) {
        let firstNameOwner = this.getPartner.ownerName.split(' ');
        let firstNamePartner = this.getPartner.name.split(' ');
        createdByName =
          firstNameOwner[0] + ' on behalf of ' + firstNamePartner[0];
      }

      let attachmentName = null;
      let attachmentFileBase64 = null;
      if (this.newAttachment) {
        attachmentName = this.newAttachment?.name ?? null;
        attachmentFileBase64 = await this.toBase64(this.newAttachment);
      }

      const data = {
        conversationId: this.selectedConversation?.id,
        message: this.newMessage,
        attachmentName: attachmentName ?? null,
        attachmentFile: attachmentFileBase64 ?? null,
        createdByName: createdByName
      };

      await this.addMessage(data)
        .then(() => {
          this.newMessage = '';
          this.newAttachment = null;
          this.$refs.textEditor.clear();
          this.$store.commit('showSnackbar', {
            message: 'Message sended.',
            color: 'success'
          });
        })
        .catch((err) => {
          const message =
            err?.response?.data?.errors?.[0] ||
            err?.response?.data?.message ||
            'Error';
          this.$store.commit('showSnackbar', {
            message: message,
            color: 'error'
          });
        })
        .finally(() => {
          this.newMessageLoading = false;
        });
    },
    scrollToBottom() {
      if (this.$refs.messageComponents) {
        const lastComponent =
          this.$refs.messageComponents[this.$refs.messageComponents.length - 1];

        if (lastComponent) {
          this.$vuetify.goTo(lastComponent, {
            container: this.$refs.messageContainer,
            duration: 0
          });
        }
      }
    },
    async toBase64(file) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
      });
    }
  },
  updated() {
    this.$nextTick(() => this.scrollToBottom());
  },
  async toBase64(file) {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
    });
  }
};
</script>

<style scoped>
#support-chat {
  background: #fff;
  height: 100%;
}
.messages-main-container {
  display: flex;
  flex-direction: column;
}

.messages-card {
  padding: 0px 10px 0px 10px;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 490px);
}

.text-editor-container {
  margin: 0;
  padding: 12px;
  margin-top: 10px;
}
</style>
